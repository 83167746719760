import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostTemplateDetails from '../components/PostTemplateDetails'

class PostTemplate extends React.Component {
  render() {
    const { title, subtitle } = this.props.data.site.siteMetadata
    const post = this.props.data.markdownRemark
    const { title: postTitle, description: postDescription, image, tags, date } = post.frontmatter
    const description = postDescription !== null ? postDescription : subtitle

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{`${postTitle} - ${title}`}</title>
            <meta name="description" content={description} />

            <meta itemprop="name" content={postTitle} />
            <meta itemprop="description" content={description} />

            <meta name="twitter:card" content={description} />
            <meta name="twitter:title" content={postTitle} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:site" content="avraam.dev" />
            <meta name="twitter:creator" content="avraamakis" />
            { image ? <meta name="twitter:image:src" content={image} /> : null }

            <meta name="og:title" content={postTitle} />
            <meta name="og:description" content={description} />
            <meta name="og:site_name" content="avraam.dev" />
            <meta name="og:locale" content="en_US" />
            <meta name="og:type" content="article" />
            { image ? <meta property="og:image" content={image} /> : null }

            <meta name="article:section" content="Technology" />
            <meta name="article:published_time" content={date} />
            <meta name="article:author" content="Avraam Mavridis" />
            <meta name="article:tag" content={tags} />
          </Helmet>
          <PostTemplateDetails {...this.props} />
        </div>
      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        author {
          name
          twitter
        }
        disqusShortname
        url
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        tagSlugs
        slug
      }
      frontmatter {
        title
        tags
        date
        description
        postClassName
      }
    }
  }
`
